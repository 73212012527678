

import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SupplierDetail extends Vue {
  @Prop() readonly id!: string;

  activeName = ''

  activeNameChanged ({ name }: any) {
    this.$router.replace(`/supplier/detail${this.id}/${name}`).catch(err => err)
  }

  // 解决点击返回没有返回上级页面问题
  get back () {
    const length = this.$route.path.split('/').length - 1
    return this.$route.path.split('/')[length] !==
    this.activeName
      ? ''
      : '/supplier'
  }

  created () {
    const names = ['info', 'product', 'communicate', 'warehouse']
    for (const name of names) {
      if (this.$route.path.indexOf(name) !== -1) {
        this.activeName = name
      }
    }
  }
}
